export function getPwValidList() {
  let validList: {
    id: string,
    name: string,
    count: number,
    expCount: number,
    regX: RegExp
  }[] = [
    { id: "MinChars", name: "At least 8 characters", count: 0, expCount: 8, regX: null },
    { id: "LowerCase", name: "A lowercase letter", count: 0, expCount: 1, regX: /^[a-z]*$/ },
    { id: "UpperCase", name: "An uppercase letter", count: 0, expCount: 1, regX: /^[A-Z]*$/},
    { id: "Numbers", name: "A number", count: 0, expCount: 1, regX: /^[0-9]*$/},
    { id: "SpecialCharacters", name: "A symbol", count: 0, expCount: 1, regX: /^\W$/},
  ]

  return validList;
}

